.outerCover{
    padding: 1rem;
    padding-bottom: .25rem;
    border-radius: .375rem;
    background-color: #f0f0f0;
    border: 1px solid #374151;
    margin: .5rem;
}
.innerBoxAdmin{
    background-color: #e6e6fa;
    width: fit-content;
    border-radius: .375rem;
    color: #1f2937;
    padding: 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
    margin-left: auto;
}
.innerBox{
    background-color: #fff9c4;
    width: fit-content;
    border-radius: .375rem;
    color: #1f2937;
    padding: 1rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
}
.attachmentBox{
    cursor: pointer;
    border-radius: .375rem;
    border: 1px solid #4b5563;
    padding: .5rem;
    margin-top: .5rem;
    margin-bottom: .25rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.replyTime{
    text-align: end;
    color: #6b7280;
    font-size: 0.75rem ;
    line-height: 1rem ;
}
.attachmentText{
    font-size: .9rem;
}

.inputArea {
    background-color: #d1d5db;
    display: flex;
    align-items: center;
    border-radius: 9999px;
    width: 100%;
    padding: .5rem;
}

.pin{
    font-size: 1.25rem ;
    line-height: 1.75rem ;
    width: 50px;
}
.scrollCover{
    height: 250px;
    overflow-y: auto;
}
.input{
    border-radius: .375rem;
    padding:.4rem;
    width:100%
}
.send{
    font-size: 1.5rem;
    line-height: 2rem ;
    width: 50px;
}
.attachmentTextBox {
    position: relative;
    width: 100%;
    padding-top: .25rem;
    padding: .25rem .5rem;
    margin-bottom: .25rem;
    background-color: #90EE90 ;
    border-radius: .375rem;
}
.attachmentCloseBtn {
    margin-left: auto;
    text-align: end;
    width: 195%;
    font-size: 1.5rem;
}
.attachText{
    color: #374151;
}