.modalCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wideModal :global(.modal-dialog) {
  min-width: 600px; /* Adjust width as needed */
}

.selectBox {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: text;
  background: white;
  position: relative;
}

.optionsDropdown {
  position: absolute;
  width: 94%;
  max-height: 150px; /* Only 3 options visible */
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 10;
}

.showOptions {
  display: block;
}

.optionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
}

.optionItem:hover {
  background: #f8f9fa;
}

.uploadIcon {
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
  transition: color 0.2s ease-in-out;
}

.uploadIcon:hover {
  color: #0056b3;
}

.fileInput {
  display: none;
}

.searchInput {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  background: transparent;
}

.selectedFilesContainer {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ul {
  margin-top: 5px;
  padding-left: 20px;
}

.uploadButton {
  margin-right: 10px;
}

.statusMessage {
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
}

.errorMessage {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.successMessage {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.modalCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}