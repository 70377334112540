:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

.table-btn {
  margin-bottom: 43px;
  margin-top: 20px;
  margin-left: 368px;
}

.form-card1 {
  /* margin-top: 10px; */
  margin-left: 15px;
  margin-right: 630px;
  margin-bottom: 15px;
}
.form-card {
  /* margin-top: 10px; */
  margin-left: 15px;
  margin-right: 30px;
}

.component {
  margin-left: 262px;
}

.my-table {
  border-collapse: collapse;
  margin-top: 16px;
}

.my-table,
.my-table th,
.my-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.my-table th {
  background-color: #f2f2f2;
}
.my-table1 {
  background-color: #f2f2f2;
  min-width: 200px;
}

.custom-tab {
  padding: 5px 10px;
  font-size: 0.84rem;
  height: 50px;
  /* margin-right: 2px;    */
}

.button {
  padding: 4px 24px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2.5px);
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.288px;
  color: #fff;
}

.light {
  background: linear-gradient(28deg, #55b76c 13.91%, #c6ee4a 79.49%);
}
.back {
  background: linear-gradient(28deg, #595a59 13.91%, #3d3d3c 79.49%);
}

@media (max-width: 768px) {
  .scrollable-table-container {
  }
  .my-table th,
  .my-table td {
    padding: 8px;
    font-size: 14px;
  }
}

.centerdivision {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
}
/* .right {
width: 100%;
} */
.left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info {
  margin: 44px 0;
}
.name {
  color: rgba(255, 255, 255, 0.8);
  font-family: Raleway;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: capitalize;
  flex: 1;
}
.logo {
  display: flex;
  align-items: center;
  min-width: 341px;
  height: auto;
}

.disText {
  color: rgb(255, 255, 255);
  font-family: Raleway;
  font-weight: 400;
}

.disTextHead {
  color: rgb(255, 255, 255);
  font-family: Raleway;
  font-size: 1.5rem;
  font-weight: 400;
}

.btn1 {
  margin-left: 1192px;
  border-radius: 11px;
  border: none;
}

.btn2 {
  margin-left: 10px;
  border-radius: 11px;
  border: none;
}

.btnTop1 {
  margin-left: 982px;
  border-radius: 11px;
  border: none;
}

.btnTop2 {
  margin-left: 10px;
  border-radius: 11px;
  border: none;
}

.table-column-fixed td:first-child {
  width: 100px;
  min-width: 250px;
}

.maxWidth {
  max-width: 500px;
  min-width: 100px;
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollable-table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 80vh;
  width: 100%;
}

.table-responsive1 {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.w-50,
.min-w-58,
.sm\:min-w-60,
.sm\:w-60 {
  width: 100% !important;
}
@media (min-width: 768px) {
  .w-50 {
    width: 50%;
  }
  .min-w-58 {
    min-width: 58%;
  }
  .sm\:min-w-60 {
    min-width: 60%;
  }
  .sm\:w-60 {
    width: 60%;
  }
}

.custom-tab.dark {
  background-color: #333;
  color: white;
  border-color: #222;
}

.nav-tabs {
  margin-bottom: 1rem;
  border-bottom: 0;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  color: #495057;
  background-color: #fff;
  margin-right: 2px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
  z-index: 1000;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

/* Responsiveness */
@media (max-width: 768px) {
  .nav-tabs {
    flex-wrap: wrap;
  }
  .nav-tabs .nav-item {
    width: 89px;
  }

  .nav-tabs .nav-link {
    margin-right: 0;
    margin-bottom: 2px;
    text-align: center;
  }
  .btn-resp {
    flex-direction: row;
    align-items: center;
  }
  .btn-resp button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.table-responsive {
  overflow-x: auto;
  max-width: 100%;
}

.my-table th,
.my-table td {
  white-space: nowrap;
}

@media (max-width: 768px) {
  .my-table th,
  .my-table td {
    white-space: normal;
  }
}

.table-wrapper {
  width: 100% !important;
  max-width: 100%;
}

.buttonloader {
  display: inline-block;
}
.buttonloader .spinner-border {
  width: 1.5em;
  height: 1.5em;
}

.button1 {
  padding: 4px 19px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2.5px);
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.288px;
  color: #fff;
}

@media (max-width: 763px) {
  .component {
    margin-left: 0px;
  }
  /* .component-full-width {
      transform: translateX(0);
      transition: transform 0.3s ease;
    } */
}

.asterisk:-moz-placeholder {
  color: #f00;
  opacity: 1;
}
.asterisk::-moz-placeholder {
  color: #f00;
  opacity: 1;
}
.asterisk:-ms-input-placeholder {
  color: #f00;
}

.select-dropdown {

}

@media (max-width: 430px) {
  .select-dropdown {
    width: 23rem;
  }
}

@media (max-width: 344px) {
  .select-dropdown {
    width: 18rem;
  }
}

.scrollable-table-container2 {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1rem;
}

.warning{
  color: rgb(222, 37, 37);
}

.assetInfo{
  color: rgb(155, 155, 241);
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background-color: rgb(161, 161, 161);
  z-index: 10;
}

.scrollable-table-container3 {
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.custom-table th,
.custom-table td {
  vertical-align: middle;
  text-align: left;
  padding: 12px;
}
.custom-table thead th {
  background-color: #f8f9fa;
  color: #333;
  border-bottom: 2px solid #dee2e6;
}
.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.search-container {
  /* position: absolute;
  top: 0;
  right: 20px;
  margin-top: 20px; */
}

.search-container input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

.footer {
  position: relative;
  margin-left: 2rem;
}

@media (max-width: 430px) {
  .resp-body {
    align-items: center;
  }
}

@media (max-width: 344px) {
  .forgot-btn {
    margin-right: none;
  }
}

.status-btn {
  background-color: hsl(108, 73%, 39%);
  /* background-color: hsla(150, 40%, 39%, 0.502); */
  color: white;
  padding: 5px 5px;
  width: 86px;
  border-radius: 5px;
  margin: 0px;
  text-align: center;
  font-size: 12px;
  /* cursor: not-allowed;
  pointer-events: all !important; */
}

/* .status-btn:hover {
  pointer-events: none;
} */

.status-btn2 {
  background-color: #e95c5c;
  color: white;
  padding: 5px 5px;
  /* max-width: 86px; */
  border-radius: 5px;
  margin: 0px;
  text-align: center;
  font-size: 12px;
  border: none;
}

.status-btn2:hover {
  pointer-events: none;
}

.status-btn3 {
  /* background-color: hsla(204, 94%, 46%, 0.502); */
  background-color: hsla(225.4deg 94% 46% / 90%);
  color: white;
  padding: 5px 5px;
  width: 86px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  margin: 0px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 1s linear infinite;
}

.customization-feild{
  margin-top: 10px;
  display: flex;
  justify-content:flex-end;
}
.editorStyle{
  overflow-y: auto;
  height: 300px;
}
.ck.ck-content {
  min-height: 400px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlayContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  min-width: 50%;
  max-width: auto;
  height: auto;
  max-height: 90%;
  overflow-y: auto;
}

.overlayInnerContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    min-width: 50%;
    max-width: 90%;
    height: auto;
    max-height: 90%;
}

.duplicateTable{
  max-height: 60vh;
  overflow-y: auto !important;
  max-width: 99%;
  overflow-x: auto;
}

.overlayButton{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 50px;
}

.titleBox {
  display: flex;
  flex-direction: column;
  margin: 1rem .5rem ;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: .5rem;
  font-size: 1.5rem;
  cursor: pointer;
}


.labels {
  margin: .2rem 0 ;
  font-size: 1.1rem;
}

.inputs {
  color: rgb(93, 93, 93);
}

.epcJourney {
  font-size: 100px;
}

.epcFlowLabel {
  min-width: 200px;
}