.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.breadcrumbContainer {
  margin-bottom: 10px;
}

.heading {
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
  font-size: x-large;
}

/* Flexbox container to separate input and buttons */
.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures input stays on the left & buttons on the right */
  width: 100%;
}

/* Search Input */
.searchContainer {
  position: relative;
  width: 100%;
  max-width: 420px; /* Keeps input width fixed */
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchInput {
  width: 100%;
  padding: 12px 40px 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.dropdownIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #666;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.dropdownIcon:hover {
  color: #000;
}

/* Dropdown should match input width */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Same width as input */
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 10;
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

/* Buttons aligned to the right without extra styling */
.buttonContainer {
  display: flex;
  gap: 10px; /* Space between buttons */
}


  
  .navItems {
    display: flex;
    flex-direction: column;
  }
  
  .navItem {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid;
  }
  
  .navItem:hover {
    background-color: #cac5c5;
  }

  
  .navItem .icon {
    margin-right: 10px;
    color: #666;
  }
  
  .selectedNavItem {
    background-color: #e6f2ff;
    border-left: 4px solid #007bff;
  }
  
  
  .cardTitle {
    margin-bottom: 20px;
    color: #333;
    font-weight: 600;
  }
  
  .permissionsTable {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  
  .permissionToggle {
    position: relative;
  }
  
  .permissionToggle input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
  
  .noteText {
    margin-top: 20px;
    color: #666;
    text-align: center;
    font-style: italic;
  }
  
  .disabledCard {
    opacity: 0.6;
    pointer-events: none;
  }
  
  .toggleCard {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
  }
  
  
  
  .customSwitch {
    position: relative;
  }
  
  .customSwitch input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .switchLabel {
    display: inline-block;
    width: 120px;
    height: 40px;
    background-color: #ddd;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .switchLabel.active {
    background-color: #28a745;
  }
  
  .switchLabel.active .switchButton {
    transform: translateX(30px);
  }
  
  .switchButton {
    position: absolute;
    height: 36px;
    width: 36px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .switchOn, .switchOff {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight: bold;
  }
  
  .switchOn {
    left: 10px;
    color: white;
    opacity: 0;
  }
  
  .switchOff {
    right: 10px;
    color: #666;
  }
  
  .switchLabel input:checked + .switchLabel {
    background-color: #28a745;
  }
  
  .switchLabel input:checked + .switchLabel .switchButton {
    transform: translateX(80px);
  }
  
  .switchLabel input:checked + .switchLabel .switchOn {
    opacity: 1;
  }
  
  .switchLabel input:checked + .switchLabel .switchOff {
    opacity: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f4f6f9;
    min-height: 100vh;
  }
  

  
  .navItem:last-child {
    border-bottom: none;
  }
  
  .navItem:hover {
    background-color: #f0f0f0;
  }
  
  .navItem .icon {
    margin-right: 10px;
    color: #666;
  }
  
  .selectedNavItem {
    background-color: #e6f2ff;
    border-left: 4px solid #007bff;
  }

  
  .permissionsHeader {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .permissionHeaderItem {
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
  }
  
  .permissionsRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }


  .content {
    display: flex;
    gap: 20px;
    flex: 1;
    height: 500px; /* Fixed height */
  }
  
  .left, .right {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 400px; /* Fixed height */
  }
  
  .left {
    flex: 1;
  }
  
  .leftHeader {
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f8f9fa;
    flex-shrink: 0; /* Prevent header from shrinking */
  }
  
  .leftHeader h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .navItemsContainer {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
  
  .navItemsContainer::-webkit-scrollbar {
    width: 8px;
  }
  
  .navItemsContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .navItemsContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .navItemsContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .navItems {
    display: flex;
    flex-direction: column;
  }
  
  .navItem {
    display: flex;
    align-items: center;
    padding:15px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease;
  }
  
  .navItem:last-child {
    border-bottom: none;
  }
  
  .right {
    flex: 1.5;
  }
  
  /* Permission Column Layout */
  .permissionsColumn {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }
  
  /* Each row containing a permission name and toggle */
  .permissionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: none; /* Ensures no lines appear */
  }
  
  /* Permission Name Styling */
  .permissionLabel {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  
  /* Toggle Container */
  .permissionToggleContainer {
    display: flex;
    align-items: center;
  }
  

  


    
  .toggleWrapper {
    position: relative;
  }
  
  .toggleWrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .toggleLabel {
    display: inline-flex;
    align-items: center;
    width: 70px;
    height: 36px;
    background-color:red;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggleHandle {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    left: 2px;
    top: 2px;
    transition: transform 0.3s ease;
  }
  
  .toggleText {
    position: absolute;
    right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    transition: opacity 0.3s ease;
  }
  
  .toggleLabel.active {
    background-color: green;
  }
  
  .toggleLabel.active .toggleHandle {
    transform: translateX(34px);
  }
  
  .toggleLabel.active .toggleText {
    right: auto;
    left: 10px;
    color: white;
  }
  .navItem {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Moves delete icon to the right */
    padding: 15px;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s ease;
  }
  
  .navItem:last-child {
    border-bottom: none;
  }
  
  .navItem:hover {
    background-color: #f0f0f0;
  }
  
  /* Title text styles */
  .navItemText {
    flex-grow: 1; /* Ensures text takes available space */
  }
  
  /* Delete Icon */
  .deleteIcon {
    cursor: pointer;
    color: red;
    transition: color 0.3s ease-in-out;
  }
  
  /* .deleteIcon:hover {
    color: red; 
  } */
  /* Existing styles remain the same */

/* Updated dropdown styles to show only 3 items at a time */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Same width as input */
  max-height: 120px; /* Height for exactly 3 items (40px each) */
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 10;
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px; /* Fixed height for each item */
  display: flex;
  align-items: center;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}