.outerFormContainer{
    margin: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    text-align: end;
}
.heading{
    text-align: start;
    font-size: 25px;
}
.formContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    text-align: start;
}
.emailContainer{
    display: flex;
    flex-direction: column;
    width:100%;
    text-align: start;
}
.inputContainer {
    display: flex;
    flex-direction: column;
  }
.inputBox{
    border-radius: 4px;
    padding: 6px;
    border: 1px solid rgb(209, 207, 207);
}
.inputBoxEmail{
    border-radius: 4px;
    padding: 6px;
    border: 1px solid rgb(209, 207, 207);
    width:100%;
}
.labelStyle{
    font-size: large;
}
.submitButton{
    padding: 8px;
    border-radius: 5px;
    font-size: large;
    background-color: #252B40;
    color: white;
    margin-top: 1rem;
}
.errorText{
    color: red;
    font-size: 15px;
}