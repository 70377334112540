.overlayContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    min-width: 50%;
    max-width: auto;
    height: auto;
    max-height: 90%;
    overflow-y: auto;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }