.custom-modal .modal-body {
    position: relative;
    overflow: visible !important; /* Allow dropdown to overflow */
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  /* Single scrollbar styles */
  .single-scrollbar-select__menu-list {
    max-height: 120px !important; /* Exact height for 3 items */
    overflow-y: auto !important; /* Only this element should scroll */
  }
  
  .single-scrollbar-select__menu {
    overflow: hidden !important; /* Prevent outer container from scrolling */
  }
  
  /* Other styles kept from original */
  .task-dropdown {
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1000;
  }
  
  .list-group {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .list-group-item {
    padding: 10px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }
  
  .list-group-item:hover {
    background-color: #f0f0f0;
  }
  
  .list-group-item.active {
    background-color: #007bff;
    color: white;
  }
  
  .no-tasks {
    padding: 10px;
    color: #888;
    text-align: center;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }