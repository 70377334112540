.labelText{
    font-size: 1.5rem;
    color: rgb(89, 86, 86);
}
.toggleContainer{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
}
.custom-switch {
    position: relative;
    width: 80px;
    height: 34px;
  }
.noteText{
    text-align: center;
    font-size: 1rem;
    color: rgb(89, 86, 86);
}

  .singleToggle{
    display: flex;
    justify-content: space-between;
    margin: 1.5rem 0;
  }
  
  .custom-switch input[type="checkbox"] {
    display: none;
  }
  
  .switch-label {
    display: block;
    width: 100%;
    height: 100%;
    background-color: red;
    border-radius: 34px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .switch-button {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease-in-out;
  }
  
  .switch-on, .switch-off {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 14px;
    font-weight: bold;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }
  
  .switch-on {
    left: 10px;
    opacity: 1;
  }
  
  .switch-off {
    right: 10px;
    opacity: 0.5;
  }
  
  input[type="checkbox"]:checked + .switch-label {
    background-color: green;
  }
  
  input[type="checkbox"]:checked + .switch-label .switch-button {
    transform: translateX(46px);
  }
  
  input[type="checkbox"]:checked + .switch-label .switch-on {
    opacity: 1;
  }
  
  input[type="checkbox"]:checked + .switch-label .switch-off {
    opacity: 0;
  }
  
  input[type="checkbox"]:not(:checked) + .switch-label .switch-on {
    opacity: 0;
  }
  
  input[type="checkbox"]:not(:checked) + .switch-label .switch-off {
    opacity: 1;
  }
  